<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('system_usergroup.detailDialog') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="80px"
        :form-field="formField"
        :grid="{ span: 24 }"
        :view-flag="viewFlag"
      />
    </div>
    <div slot="footer">
      <el-button @click="handleDialogClose">
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
const BASEURL = {
  update: '/system/group/update',
  get: '/system/group/get',
  save: '/system/group/save',

  office: '/system/office/list',
  role: '/system/role/list'
}
export default {
  name: 'SystemUsergroupDetail',
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  data () {
    return {
      formModel: { status: 'Y', roleList: [] },

      sysRoleList: []
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () { return this.showFlag },
      set (val) { this.$emit('update:showFlag', val) }
    },

    status () { return this.$getDictList('data_status') },

    formField () {
      return [
        {
          prop: 'code',
          label: this.$t('system_usergroup.code'),
          type: 'Input',
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'name',
          label: this.$t('system_usergroup.name'),
          type: 'Input',
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'roleList',
          label: this.$t('system_usergroup.roleList'),
          type: 'Select',
          component: {
            optionList: this.sysRoleList,
            multiple: true,
            valueKey: 'id'
          }
          // rules: {
          //   type: 'array',
          //   trigger: 'change',
          //   message: this.$t('validate.notSelect'),
          //   required: true
          // }
        },
        {
          prop: 'status',
          label: this.$t('system_usergroup.status'),
          type: 'Select',
          component: { optionList: this.status },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'remarks',
          label: this.$t('system_usergroup.remarks'),
          type: 'Input',
          component: { type: 'textarea' },
          col: { xs: 24, sm: 24, md: 24 }
        }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },

    getSysRoleList () {
      this.$axios.post(BASEURL.role, {})
        .then(resp => {
          const respData = resp.data
          this.sysRoleList = respData.list.map(item => { return { key: item.id, value: item, label: item.name } })
        })
    },

    handleDataSubmit () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })

          const submitModel = this.$_.cloneDeep(this.formModel)

          this.$axios
            .post(this.detailData.id ? BASEURL.update : BASEURL.save, submitModel)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },

    handleDialogOpen () {
      if (!this.detailData.id) {
        this.getSysRoleList()
        return
      }
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })

      this.$axios
        .post(BASEURL.get, { id: this.detailData.id })
        .then(resp => {
          const respData = resp.data
          this.initialSelectOptionList(respData.company)
          // delete respData.password
          this.formModel = respData
        })
        .finally(() => {
          loadingFlag.close()
        })
    },

    initialSelectOptionList (company) {
      // this.companyList = [{ key: company.id, value: company, label: company.name }]
      this.getSysRoleList()
    },

    handleDialogClosed () {
      this.$refs.form.resetFields()
      this.formModel = { status: 'Y', roleList: [] }
    }
  }
}
</script>
